<template>
  <v-container
    fluid
    :class="'d-flex justify-space-between align-center'"
    :style="compact ? 'overflow-x: scroll' : ''"
  >
    <selection-sheet
      sheet-style="width: 100%; margin: 0.5rem 1rem"
      v-for="t of types"
      :key="t.id"
      :color="t.color"
      item-key="id"
      item-label="name"
      :item="t"
      :icon-key="t.icon"
      v-model="type"
      :small="compact"
      :fontSize="compact ? '0.75rem' : '1rem'"
    ></selection-sheet>
  </v-container>
</template>

<script>
import SelectionSheet from "../common/SelectionSheet.vue";

export default {
  name: "DeviceTypeSelect",

  components: { SelectionSheet },

  props: {
    inputType: { type: Number },
    compact: { type: Boolean, default: false },
    includeNone: { type: Boolean, default: false },
  },

  model: {
    prop: "inputType",
  },

  data() {
    return {
      defaultTypes: [
        {
          id: 0,
          name: "LoRaWAN",
          color: "primary",
          icon: "mdi-signal-variant",
        },
        {
          id: 1,
          name: "API",
          color: "orange",
          icon: "mdi-api",
        },

        {
          id: 2,
          name: "MQTT",
          color: "green",
          icon: "mdi-access-point",
        },

        {
          id: 3,
          name: "NodeRed",
          color: "red",
          icon: "mdi-sitemap-outline",
        },
        {
          id: 4,
          name: "Sparkplug",
          color: "blue",
          icon: "mdi-shimmer",
        },
      ],
    };
  },

  computed: {
    type: {
      get() {
        return this.inputType;
      },
      set(value) {
        this.$emit("input", value);
      },
    },

    types() {
      let tps = this.defaultTypes;

      if (this.includeNone)
        tps.push({
          id: null,
          name: "All",
          color: "grey",
          icon: "mdi-arrow-expand-all",
        });

      return tps;
    },
  },
};
</script>